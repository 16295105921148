<script setup>
    if (!process.client) {
        throw new Error('This component must be executed Client Side!');
    }

    const isShown = ref(false);
    const { logEvent } = useMetrics();

    const preselectionCookie = useCookie('preselection', {
        maxAge: 60 * 60 * 24 * 30, // 30 days
    });
    let message, countryCode, city, region;

    let timeoutId;
    const lottery = Math.random() < 0.5;
    if (!preselectionCookie.value && lottery) {
        // Check if user has already seen the message
        const dataJ = await fetch('/worker/preselection');
        const data = await dataJ.json();
        message = data.message;
        countryCode = data.countryCode;
        city = data.city;
        region = data.region;

        timeoutId = setTimeout(() => {
            isShown.value = true;

            logEvent('showing_preselection', city, {
                countryCode,
                region,
            });

            setTimeout(() => {
                isShown.value = false;
            }, 5000);
        }, 5000);

        onUnmounted(() => {
            timeoutId && clearTimeout(timeoutId);
        });
    }

    function dismiss() {
        preselectionCookie.value = true;
        isShown.value = false;

        logEvent('dismiss_preselection', city, {
            countryCode,
            region,
        });
    }
</script>

<template>
    <div
        v-if="isShown"
        class="fixed bottom-32 right-4 z-50"
    >
        <div
            class="flex w-full max-w-xs items-center rounded-lg bg-neutral p-4 text-neutral-content"
            role="alert"
        >
            <div
                class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-800 dark:text-orange-200"
            >
                <svg
                    class="h-4 w-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 20"
                >
                    <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15.147 15.085a7.159 7.159 0 0 1-6.189 3.307A6.713 6.713 0 0 1 3.1 15.444c-2.679-4.513.287-8.737.888-9.548A4.373 4.373 0 0 0 5 1.608c1.287.953 6.445 3.218 5.537 10.5 1.5-1.122 2.706-3.01 2.853-6.14 1.433 1.049 3.993 5.395 1.757 9.117Z"
                    />
                </svg>
                <span class="sr-only">Fire icon</span>
            </div>
            <div class="ml-3 mr-2 text-sm font-normal">{{ message }}</div>
            <button
                @click="dismiss"
                type="button"
                class="btn btn-ghost btn-sm"
                aria-label="Close"
            >
                <span class="sr-only">Close</span>
                <svg
                    class="h-3 w-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                >
                    <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                </svg>
            </button>
        </div>
    </div>
</template>
