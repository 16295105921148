<script setup lang="ts">
    const { globalAssets } = useGlobalAssets();
</script>

<template>
    <div
        class="indicator"
        title="Work with selected documents"
    >
        <span class="badge indicator-item badge-primary">
            {{ globalAssets.length }}
        </span>
        <NuxtLink
            class="btn btn-outline btn-sm md:btn-md md:py-2"
            to="/cart"
        >
            <svg
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                class="h-6 w-6 md:h-8 md:w-8"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                ></path>
            </svg>
        </NuxtLink>
    </div>
</template>
