<script setup lang="ts">
    // On Cmd or Ctrl + K, focus the search box
    const focusSearchBox = (event: KeyboardEvent) => {
        if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
            event.preventDefault();
            document.querySelector('input[type="search"]')?.focus();
        }
    };

    onMounted(() => {
        window.addEventListener('keydown', focusSearchBox);
    });

    onUnmounted(() => {
        window.removeEventListener('keydown', focusSearchBox);
    });
</script>

<template>
    <div class="flex min-h-screen flex-col scroll-smooth">
        <!-- NavMenu -->
        <TheHeader />
        <slot />

        <span class="flex flex-1"></span>
        <TheFooter />
    </div>
    <ClientOnly>
        <Preselection />
    </ClientOnly>
</template>
