<script setup>
    import { languages } from '~/labels';
    const preferredLanguage = usePreferredLanguage();
</script>

<template>
    <select
        v-model="preferredLanguage"
        class="select select-bordered select-sm md:select-md"
    >
        <option
            disabled
            selected
        >
            Filter by Language
        </option>
        <option :value="[]">Show all languages</option>
        <option
            v-for="(label, code) in languages"
            :value="[code]"
        >
            {{ label }}
        </option>
    </select>
</template>
