<template>
    <footer class="border-t">
        <div class="container flex flex-wrap items-center justify-between p-8">
            <DarkModeToggle />
            <div>
                <p>© All rights reserved.</p>
                <p>Flügger Group A/S</p>
            </div>
        </div>
    </footer>
</template>
