<script setup lang="ts">
    import { useWindowScroll } from '@vueuse/core';
    const { y } = useWindowScroll();

    const preferredLanguage = usePreferredLanguage();
    const { globalAssets } = useGlobalAssets();

    const route = useRoute();
    function refreshState() {
        if (route.path === '/') {
            preferredLanguage.value = [];
            globalAssets.value = [];

            // Cause full page reload
            window.location.reload();
            return false;
        }

        return true;
    }
</script>

<template>
    <header
        class="sticky top-0 z-50 transition-all duration-300"
        :class="{
            'w-full bg-base-200 shadow-md': y > 0,
            'bg-transparent': y === 0,
        }"
    >
        <div
            class="container-p-x container flex flex-col items-center justify-between gap-2 bg-transparent py-4 md:flex-row"
        >
            <NuxtLink
                to="/"
                @click="refreshState"
                title="Also known as PIXI ;)"
            >
                <img
                    src="/logo.svg"
                    class="h-6 opacity-80 sm:h-7 xl:h-9 dark:opacity-[60%] dark:invert"
                    alt="Flügger Document Manager"
                />
            </NuxtLink>

            <div class="flex justify-between gap-2">
                <LanguageSwitcher />
                <DownloadButton />
            </div>
        </div>
    </header>
</template>
