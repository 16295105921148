<script setup>
    import { useDark, usePreferredDark } from '@vueuse/core';

    const isDark = useDark({
        selector: 'html',
        attribute: 'data-theme',
        valueDark: 'dark',
        valueLight: 'autumn',
    });

    const isDarkPreferred = usePreferredDark();

    const { logEvent } = useMetrics();

    watch(isDark, value => {
        logEvent('dark_mode', value ? 'on' : 'off', {
            // Log if Dark Mode is preferred by browser
            dark_mode_preferred: isDarkPreferred.value,
        });
    });
</script>

<template>
    <div
        class="form-control"
        title="Toggle dark mode"
    >
        <label class="label cursor-pointer gap-2">
            <span class="label-text">{{ isDark ? 'Lights off' : 'Lights on' }}</span>
            <input
                type="checkbox"
                class="toggle"
                v-model="isDark"
            />
        </label>
    </div>
</template>
